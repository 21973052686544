import { useState, useRef } from 'react';
import { StUploadForm, StFileInput, StLabel, StButton, StNav, StInputCondese, StInputBlock, StInputGroup, StWrapper } from './styled-ui';
import { Dropzone } from './Dropzone';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";

const fileAttributes = [
  { id: 'date_created', label: 'Date created', type: 'date' },
  { id: 'inspection_date', label: 'Inspection date', type: 'date' },
  { id: 'inspection_name', label: 'Inspection name', size: 20 },
  { id: 'inspection_id', label: 'Inspection id', size: 10 },
  { id: 'mileage', label: 'Mileage', type: 'number' },
  /*{
    id: 'data_type', label: 'Data type', type: 'select',
    //defaultValue: 'wav',
    values: [
      { value: 'wav', label: 'WAV' },
      { value: 'obd', label: 'OBDII' },
      { value: 'csv', label: 'Data Log' }
    ]
  },*/
  {
    id: 'engine_status', label: 'Engine status', type: 'select',
    //defaultValue: 'idle',
    values: [
      { value: 'idle', label: 'IDLE' },
      { value: 'active', label: 'Running' },
      { value: 'idle_and_active', label: 'IDLE & Running' }
    ]
  },
  { id: 'rpm', label: 'RPM', type: 'number' },
  {
    id: 'vehicle_status', label: 'Vehicle status', type: 'select',
    //defaultValue: 'healthy',
    values: [
      { value: 'healthy', label: 'Healthy' },
      { value: 'faulty', label: 'Faulty' }
    ]
  },
  { id: 'fault_type', label: 'Fault type', type: 'select', 
    values: [
      /*{ value: 'UNKNOWN_ENGINE_SOUND', label: 'Unknown Engine Sound' },
      { value: 'LIFTER_TICK', label: 'Lifter Tick' },
      { value: 'ROD_KNOCK', label: 'Rod Knock' },
      { value: 'LIFTER_TICK_AND_ROD_KNOCK', label: 'Lifter Tick & Rod Knock' },
      { value: 'INJECTOR_CLICK', label: 'Injector Click' },
      { value: 'STEERING_PUMP_WHINE', label: 'Steering Pump Whine' },
      { value: 'AC_BEARING_RATTLE', label: 'A/C Bearing Rattle' },
      { value: 'EXHAUST_RUMBLE', label: 'Exhaust Rumble' },
      { value: 'UES_AND_EX_RUMBLE', label: 'Unknown Engine Sound & Exhaust Rumble' }*/

      { value: 'INTERNAL_ENGINE_NOISE', label: 'Internal Engine Noise'},
      { value: 'ACCESSORY_NOISE', label: 'Accessory/Belt Noise'},
      { value: 'EXHAUST_NOISE ', label: 'Exhaust Noise'},
      { value: 'TRANSMISSION_NOISE', label: 'Transmission Noise'},
      { value: 'OTHER_NOISE', label: 'Other Noise'}
    ]
  }
];

const Uploader = ({ projectId, tkn, onUploadEnd }) => {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(0);

  const { register, handleSubmit, getValues, reset, control, formState: { errors, isDirty, isValid } } = useForm({
    defaultValues: {
      date_created: new Date(),
      inspection_date: new Date(),
      inspection_name: '',
      inspection_id: '',
      //data_type: 'wav',
      engine_status: 'idle',
      vehicle_status: 'healthy',
      fault_type: '',
      mileage: 0,
      rpm: 0
    }
  });

  //console.info('------file u[ploader errors', errors, isDirty, isValid)
  //console.info('------file u[ploader values', getValues(), isDirty, isValid)

  const xhrRef = useRef(null);
  const fileInputRef = useRef(null);

  const canCancel = uploadStatus === 1 || files[0];
  const canUpload = uploadStatus === 0 && files[0];

  const onFileSelect = (event) => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onFileChange = (event) => {
    setFiles([...files, ...event.target.files]);
  };

  const onDrop = (file) => {
    setFiles([...files, ...file]);
  }

  const clearUploadData = () => {
    setUploadStatus(0);
    setProgress(0);
    setFiles([]);
    reset();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const cancelUpload = () => {
    if (xhrRef.current) {
      xhrRef.current.abort();
    }
    clearUploadData();
  };

  const startUpload = (data) => {
    //console.info('--- start upload', data)
    const formData = new FormData();
    files.forEach((file, idx) => {
      formData.append(`file${idx + 1}`, file);
    });
    //formData.append('file', files);

    Object
      .keys(getValues())
      .forEach((key) => {
        if (key === 'inspection_date' || key === 'date_created') {
          const dateValue = Date.parse(getValues(key));
          const date = isNaN(dateValue) ? new Date() : new Date(dateValue);
          formData.append(key, date.toISOString());
        } else {
          formData.append(key, getValues(key));
        }
      });

    const xhr = new XMLHttpRequest();
    xhrRef.current = xhr;

    //setResults(null);

    //xhr.open('POST', `http://localhost:5002/api/projects/${projectId}/files`);
    xhr.open('POST', `https://flow.globalsense.ai/api/projects/${projectId}/files`);
    xhr.setRequestHeader('Authorization', `Bearer ${tkn}`);
    xhr.upload.addEventListener('progress', handleUploadProgress);
    xhr.addEventListener('load', ((e) => {
      //console.info('--- upload complete', e)
      clearUploadData();
      onUploadEnd(e);
    }));

    xhr.addEventListener('error', ((e) => {
      //console.info('--- upload error', e)
      setUploadStatus(-1);
      onUploadEnd(e);
    }));

    setUploadStatus(1);
    xhr.send(formData);
  };

  const handleUploadProgress = (event) => {
    const { loaded, total } = event;
    const progress = Math.round((loaded / total) * 100);
    setProgress(progress);
  };

  return (
    <StUploadForm onSubmit={handleSubmit(startUpload)} >
      <StWrapper>
        <Dropzone onDrop={onDrop} onClick={onFileSelect} files={files} />
        <StInputBlock>
          {
            fileAttributes.map((attr) => {
              const { id, label, size = 5, type = 'text', values = null } = attr;
              return (
                <StInputGroup key={`input-${id}`}>
                  <StLabel>{label}</StLabel>
                  {type === 'select' && (
                    <select {...register(id)} key={id} disabled={false /*getValues('vehicle_status')==='healthy' && id==='fault_type'*/ } multiple={id === "fault_type" ? true:false} >
                      {
                        values.map(({ value, label }) => {
                          return (
                            <option value={value} key={`option-${value}`}>{label}</option>
                          )
                        })
                      }
                    </select>
                  )
                  }
                  {type === 'date' && (
                    <Controller
                      control={control}
                      name={id}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DatePicker
                            selected={Date.parse(value + 1)}
                            minDate={Date.parse('01/01/1990')}
                            maxDate={new Date()}
                            onChange={onChange}
                            dateFormat="dd/MM/yyyy"
                          />)
                      }}
                    />

                  )
                  }
                  {(type === 'text' || type === 'number') && (
                    <StInputCondese
                      placeholder={label}
                      id={id}
                      key={id}
                      size={size}
                      type={type}
                      {...register(id)}
                    />
                  )
                  }
                </StInputGroup>
              )
            })
          }
        </StInputBlock>
      </StWrapper>
      <StNav>
        <StFileInput type="file" accept='.wav,.json,.csv' multiple ref={fileInputRef} onChange={onFileChange} />
        <StButton type="submit" onClick={handleSubmit(startUpload)} disabled={!canUpload}>Upload</StButton>
        <StButton $isCancel={true} onClick={cancelUpload} disabled={!canCancel}>Cancel</StButton>
      </StNav>
      {progress > 0 && <progress value={progress} max="100" />}
    </StUploadForm>
  );
}

export { Uploader };