import React, { useState } from 'react';
import axios from 'axios';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from 'react-query';
import { StForm, StInput, StInputWrapper, StInputBlock, StInputGroup, StInputArea, StNav, StButton, StError, StLabel } from './styled-ui';

const emptyVehicle = { vin: '', make: '', model: '', year: '', engine: '' };
const emptyProject = { name: '', description: '' };

const ProjectCreator = ({ onSuccess, onCancel, isEditMode = false, projectData = {} }) => {

    const { register, handleSubmit, getValues, reset, control, formState: { errors, isDirty, isValid }} = useForm({
        defaultValues: isEditMode ? { 
            name: projectData.name,
            description: projectData.description,
            ...(projectData.vehicle || { ...emptyVehicle })
        } : { ...emptyProject, ...emptyVehicle }
    });
    const [error, setError] = useState(null);

    const projectMutation = useMutation({
        mutationFn: (params) => {
            return axios.post('/api/flow', { ...params })
        },
        onSuccess: (data) => {
            const { data: { status, success } } = data;
            if (status || success) {
                reset();
                onSuccess()
            } else {
                const { data: { message } } = data;
                setError(message || `We can't process your request. Please try again later.`);
            }
        },
        onError: (error) => {
            console.error('--- error', error)
            setError(error);
        }
    });

    const cancelHandler = (e) => {
        e.preventDefault();
        if (onCancel) {
            onCancel(e);
        }
        reset();
    }

    const createProject = (data) => {
        const { name, description } = data;
        const { vin, make, model, year, engine } = data;
        projectMutation.mutate({ payload: { name, description, vehicle: { vin, make, model, year, engine } }, endpoint: 'createProject' });
    };

    const updateProject = (data) => {
        const { name, description } = data;
        const { vin, make, model, year, engine } = data;
        projectMutation.mutate({ payload: { name, description, vehicle: { vin, make, model, year, engine } }, endpoint: 'updateProject', query: `/${projectData.id}` });
    };


    const creationDisabled = projectMutation.isLoading || !isValid;

    return (
        <StForm onSubmit={handleSubmit(isEditMode ? updateProject : createProject)}  $width="100%">
            {error && <StError>{error}</StError>}
            <StInput
                type="text"
                placeholder="Project name"
                id="name"
                key="name"
                $isInvalid={errors.name}
                {...register('name', { required: true, maxLength: 50})}
            />
            <StInputArea
                placeholder="Project description"
                id="description"
                key="description"
                $isInvalid={errors.description}
                {...register('description', { maxLength: 256})}
            />
            <StInputBlock>
                {
                    Object
                    .keys(getValues())
                    .filter(key => key !== 'name' && key !== 'description' && key !== 'date_created')
                    .map((key) => {
                        return (
                            <StInputGroup key={`input-${key}`}>
                                <StLabel>{key}</StLabel>
                                {
                                    key === 'year' ? (
                                        <StInputWrapper>
                                            <Controller
                                                control={control}
                                                name='year'
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                    <DatePicker
                                                        selected={Date.parse(value+1)}
                                                        minDate={Date.parse('01/01/1990')}
                                                        maxDate={new Date()}
                                                        onChange={(date) => {
                                                            const year = (new Date(date)).getFullYear();
                                                            onChange(year)
                                                        }}
                                                        showYearPicker
                                                        dateFormat="yyyy"
                                                    />)
                                                }}
                                            />
                                        </StInputWrapper>
                                        ) : (
                                        <StInput
                                            type="text"
                                            placeholder={key}
                                            id={key}
                                            key={key}
                                            size={key === 'vin' || key === 'engine' ? '20' : '10'}
                                            $isInvalid={errors[key]}
                                            {...register(key, { maxLength: 256})}
                                        />)}
                            </StInputGroup>
                        )
                    })
                }
            </StInputBlock>
            <StNav>
                <StButton type="submit" disabled={creationDisabled}>
                    {projectMutation.isLoading ? 'processing...' : isEditMode ? 'save' : 'create'}
                </StButton>
                <StButton type="button" onClick={cancelHandler} >
                    cancel
                </StButton>
            </StNav>
        </StForm>
    )
}

export { ProjectCreator };
