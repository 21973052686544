import styled from 'styled-components';

/***
 * layout components
 */

const StWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    width: 98%;
    @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
`;

const StList = styled.div`
    width: ${props => props.$wd ? props.$wd : '100%'};    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media (max-width: 900px) {
        max-width: 90vw;
        width: 90vw;
    }
`;

const StGridRow = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: ${props => props.$columnsNum ? `repeat(${props.$columnsNum}, 1fr)` : props.$columns ? props.$columns.join(' ') : '55% 35% 10%'};
    overflow: hidden;
    width: 100%;
    padding: 5px;
    margin: 2px;
    background-color: ${props => props.$isSelected ? '#9dbbf1' : '#fff;'};
    align-items: center;
    &:nth-child(odd) {
        background-color: ${props => props.$isSelected ? '#9dbbf1' : '#eee;'};
    }
    border: 1px solid #ccc;
    border-left: none;
    border-right: none;
    &:hover {
        box-shadow: 0px 0px 5px #ccc;
    }
    > button {
        width: 100%;
    }
    > audio {
        width: 100%;
        height: 25px;
        border: 1px solid lightgreen;
        border-radius: 10px;
    }
`;

const StLabel = styled.label`
    text-align: left;
    ${
        props => props.$isHeader ? 
            `color: #00d;
            word-wrap: break-word;
            padding: 5px 0;`
            :
            `color: inherit;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;`
    };
`;

const StLabelCondesed = styled(StLabel)`
    font-size: 0.8em;
}`;

const StDescription = styled.pre`
    text-align: left;
`;

const StTooltip = styled.div`
    position: absolute;
    color: #00d;
    z-index: 1;
    overflow: visible;
    margin-top: -20px;
    background: #eee;
    padding: 5px;
    padding-left: 0px;
`;

const StInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 200px;
    height: auto;
    background-color: #eee;
    margin-bottom: 10px;
    padding: 20px;
    box-sizing: border-box;
`;

const actionButtonColors = {
    'delete': 'salmon',
    'update': 'gold',
    'create': 'lightsteelblue',
    'view': 'lightgreen',
}

const StActionButton = styled.button`
    border-radius: 4px;
    padding: 5px;
    font-size: 80%;
    min-width: 50px;
    cursor: pointer;
    border: 1px solid #ccc;
    max-width: 125px;
    user-select: none;
    white-space: nowrap;
    background: ${props => props.$action ? actionButtonColors[props.$action] : '#eee'};
`;

const StError = styled.div`
    background-color: mediumvioletred;
    color: #fff;
    font-size: 0.8em;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
`;


/***
 * forms, inputs, form buttons, form buttons navigation container
 */

const StForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: ${props => props.$width ? props.$width : '30%'};
    @media (max-width: 900px) {
        width: 100%;
    }
`;

const StUploadForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 200px;
 `;

const StDropZone = styled.div`
  width: 100%;
  height: 100%;
  border: 1px dotted #4285f5;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4285f5;
  cursor: pointer;
  user-select: none;
  background-color: ${(props) => (props.$isDragging ? '#4285f5' : '#fff')};
`;

const StFileInput = styled.input`
  display: none;
`;

const StButton = styled.button`
    width: 20%;
    padding: 10px;
    min-width: 100px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? '0.5' : '1'};
    background-color: ${props => props.type && props.type === 'submit' ? '#4285f5' : '#555'};
    color: #fff;
    user-select: none;
`;

const StInput = styled.input`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    background: ${props => props.$isInvalid ? '#fcc' : '#fff'};
    box-sizing: border-box;
`;

const StInputWrapper = styled.div`
    & > div > div > input{
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        border: 1px solid #ccc;
        box-sizing: border-box;
        size: 10;
    }
`;

const StInputCondese = styled(StInput)`
    margin: 1px 0;
    padding: 2px;
`;

const StInputBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flwx-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
`;

const StInputGroup = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flwx-start;
    align-items: center;
    gap: 5px;
    > label {
        overflow: unset;
        text-overflow: unset;
    }    
`;

const StInputArea = styled.textarea`
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    box-sizing: border-box;
`;


const StNav = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
`;


export {
    StWrapper,
    StList,
    StInfo,
    StGridRow,
    StActionButton,
    StLabel,
    StLabelCondesed,
    StDescription,
    StTooltip,
    StForm,
    StInput,
    StInputWrapper,
    StInputCondese,
    StInputBlock,
    StInputGroup,
    StInputArea,
    StButton,
    StNav,
    StError,
    StUploadForm,
    StDropZone,
    StFileInput
};
