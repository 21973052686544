import React, { useState } from 'react';
import axios from 'axios';

import { useQuery } from 'react-query';
import { StGridRow, StList, StTooltip, StLabelCondesed, StActionButton } from './styled-ui';
import { StHeader, StSubTitle, StTitleInfoWrapper } from '../styled-ui';
import { Uploader } from './Uploader';

const projectFields = [
  { id: 'project_name', label: 'Project' },
];

const commonFields = [
  { id: 'original_filename', label: 'File name' },
  { id: 'date_created', label: 'Date created' },
  { id: 'inspection_date', label: 'Inspection date' },
  { id: 'inspection_name', label: 'Inspection name' },
  { id: 'inspection_id', label: 'Inspection id' },
  { id: 'mileage', label: 'Mileage' },
  { id: 'data_type', label: 'Data type' },
  { id: 'engine_status', label: 'Engine status' },
  { id: 'rpm', label: 'RPM' },
  { id: 'vehicle_status', label: 'Vehicle status' },
  { id: 'fault_type', label: 'Fault type' }
];

const commonColumns = ['3fr',
  '0.75fr', '0.75fr',
  '1fr', '1fr',
  '0.5fr', '0.5fr', '0.5fr', '0.5fr',
  '1fr', '1fr', '2fr'];

const FileBasicInfo = ({ file, projectId, isHeader = false, includeProjectName = false }) => {

  const sessionAuth = sessionStorage.getItem('sai');
  const { t } = typeof sessionAuth === 'string' ? JSON.parse(sessionAuth) : { t: null };

  const [showTooltip, toggleTooltip] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const columns = includeProjectName ? ['1fr', ...commonColumns] : commonColumns;
  const fields = includeProjectName ? [...projectFields, ...commonFields] : commonFields;

  const handleMouseEnter = () => {
    toggleTooltip(true);
  };

  const handleMouseLeave = () => {
    toggleTooltip(false);
  };

  const loadAudio = () => {
    fetch(`https://flow.globalsense.ai/api/projects/${projectId}/files/${file.id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${t}`
      }
    })
      .then(response => response.blob())
      .then(data => {
        const url = URL.createObjectURL(data);
        setAudioUrl(url);
      })
      .catch(error => console.error('Error:', error));
  }

  return (
    <StGridRow $columns={columns}>
      {
        Object.keys(fields).map((key) => {
          const { id, label } = fields[key];
          
          return (
            <StLabelCondesed $isHeader={isHeader} key={`file-${id}`}>
              <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {file ?
                  (id === 'date_created' || id === 'inspection_date') ? `${file[id]}`.split(' ')[0] : 
                  (id==="data_type" && file.original_filename.toLowerCase().indexOf('ios') !== -1) ? "OBD": file[id]
                  : label}
                {showTooltip && file && id === 'original_filename' && <StTooltip>{file.original_filename}</StTooltip>}
              </span>
            </StLabelCondesed>
          )
        })
      }
      {/*!isHeader && <StActionButton $action='delete' onClick={() => { }}>delete</StActionButton>*/}
      {
        !isHeader && file && file.data_type === 'WAV' && file.original_filename.indexOf('.wav') !== -1 && file.original_filename.toLowerCase().indexOf('ios') === -1 && audioUrl &&
        //https://flow.globalsense.ai/api/projects/36/files/81
        <audio controls src={audioUrl} autoPlay={true} title={file.original_filename}></audio>
        //<audio controls autoPlay={true} src={`/api/flow?endpoint=getFile&query=/${projectId}/files/${file.id}`} title={file.original_filename}></audio>
      }
      {
        !isHeader && file && file.data_type === 'WAV' && file.original_filename.indexOf('.wav') !== -1 && !audioUrl &&
        <StActionButton $action='edit' onClick={loadAudio}>play</StActionButton>
      }
      {
        !isHeader && (file && file.data_type === 'OBD' || file.data_type === 'LIVE' || (file.data_type === 'WAV' && file.original_filename.indexOf('.wav') === -1)) &&
        <StActionButton $action='view' onClick={() => {
          const filename = file.original_filename;//`${file.name}.${file.extension}`;
          fetch(`/api/flow?endpoint=getFile&query=/${projectId}/files/${file.id}`)
            .then((response) => {
              response.blob().then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
              });
            })
        }}>download</StActionButton>
      }

    </StGridRow>
  )
}

const Files = ({ projectId, tkn, readOnly, allFiles }) => {
  const [showUploader, toggleUploader] = useState(false);

  const { status, data, error, isFetching, refetch } = useQuery({
    queryKey: allFiles ? ['all-files'] : ['project-files', projectId],
    queryFn: async () => {
      const response = await axios(
        {
          method: 'get',
          url: '/api/flow',
          params: {
            endpoint: allFiles ? 'allFiles' : 'projectFiles',
            query: allFiles ? null : `/${projectId}/files`
          }
        }
      );
      return response.data.files;
    },
  });
  //console.info('---- Files', status, data, error, isFetching)
  const List = () => {
    if (isFetching) {
      return (<div>loading files...</div>)
    }

    return (
      <>
        {
          data === undefined || data.length === 0 ? <div>no files uploaded</div> :
            <>
              <FileBasicInfo key='header' isHeader={true} includeProjectName={allFiles} />
              {
                data.map((file) => {
                  const { id } = file;
                  //console.info('---- file', file.project_id, projectId)
                  return (<FileBasicInfo key={`file-${id}`} {...{ file, projectId: file.project_id || projectId, includeProjectName: allFiles }} />)
                })
              }
            </>
        }
      </>
    )
  }

  return (
    <StList>
      <StHeader>
        <StSubTitle>{allFiles ? 'All Uploaded Files' : 'Project Files'}{' '}
        {
            allFiles &&  <StActionButton $action='view' onClick={() => {
          const filename = `uploads-${new Date()}.csv`;
          fetch(`/api/flow?endpoint=allFiles`)
            .then((response) => {
              response.json().then((data) => {
                if (data.success && data.files) {
                  const files = data.files;
                  const csvHeaders = Object.keys(files[0]).join(',') + '\n';
                  const csvRows = files.map(file => Object.values(file).join(',')).join('\n');
                  const csvData = csvHeaders + csvRows;
          
                  const blob = new Blob([csvData], { type: 'text/csv' });

                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
                }
              });
            })
        }}>download as csv</StActionButton>


          }
        </StSubTitle>
        <StTitleInfoWrapper>
          {
            !readOnly && <StActionButton $action='create' onClick={() => { toggleUploader(!showUploader) }}>
              {showUploader ? 'cancel' : 'upload'}
            </StActionButton>
          }
        </StTitleInfoWrapper>
      </StHeader>
      {
        showUploader &&
        <Uploader
          projectId={projectId}
          tkn={tkn}
          onUploadEnd={refetch}
          onCancel={() => toggleUploader(false)} />
      }

      <List />
    </StList>
  )
}

export { Files };