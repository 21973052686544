import React, { useState } from 'react';
import axios from 'axios';

import { useQuery } from 'react-query';
import { StWrapper, StList, StGridRow, StLabel, StActionButton, StInfo } from './styled-ui';
import { StHeader, StSubTitle, StTitleInfoWrapper } from '../styled-ui';
import { ProjectInfo } from './ProjectInfo';
import { Files } from './Files';
import { ProjectCreator } from './ProjectCreator';

const ProjectBasicInfo = ({ id, name, selectProject, selectedProject }) => {
  return (
    <StGridRow $isSelected={selectedProject === id}>
      <StLabel>{name}</StLabel>
      <StLabel />
      <StActionButton onClick={() => { selectProject(id) }}>info</StActionButton>
    </StGridRow>
  )
}


const Projects = () => {
  const sessionAuth = sessionStorage.getItem('sai');
  const { t: tkn, r } = typeof sessionAuth === 'string' ? JSON.parse(sessionAuth) : { r: 1, t: null };

  const [selectedProject, selectProject] = useState(-1);
  const [showCreator, toggleCreator] = useState(false);
  const [allProjects, toggleAllProjects] = useState(false);

  const { status, data, error, isFetching, refetch } = useQuery({
    queryKey: [allProjects ? 'allProjects' : 'userProjects'],
    queryFn: async () => {
      const response = await axios(
        {
          method: 'get',
          url: '/api/flow',
          params: {
            endpoint: allProjects ? 'allProjects' : 'projects',
            //query: '/?limit=10&sort=market_cap&sort_dir=desc'
          }
        }
      );
      //console.info('-- projects response', response)
      return response.data.projects;
    },
  });

  const List = () => {
    if (isFetching) {
      return (<div>loading projects...</div>)
    }

    return (
      <>
        {
          data === undefined ? <div>no projects created</div> :
            data.map((project) => {
              const { id } = project;
              return (<ProjectBasicInfo key={`project-${id}`} {...{ ...project, selectProject, selectedProject }} />)
            })
        }
      </>
    )
  }

  return (
    <StWrapper>

      <StList $wd='29%'>
        <StHeader>
          <StSubTitle>{allProjects ? 'All' : 'Your'} Projects</StSubTitle>
          <StTitleInfoWrapper>
            {
              r === 2 && <StActionButton $action='update' onClick={() => { toggleAllProjects(!allProjects); selectProject(-1);/*refetch();*/ }}>
                {allProjects ? 'view own' : 'view all'}
              </StActionButton>
            }
            {!allProjects && <StActionButton $action='create' onClick={() => { toggleCreator(!showCreator) }}>
              {showCreator ? 'cancel' : 'create'}
            </StActionButton>
            }
          </StTitleInfoWrapper>
        </StHeader>
        {showCreator && <StInfo><ProjectCreator onSuccess={refetch} onCancel={() => toggleCreator(false)} /></StInfo>}
        <List />
      </StList>

      {
        selectedProject > 0 ?
          (
            <StList $wd='69%'>
              <ProjectInfo projectId={selectedProject} refetchProjects={refetch} readOnly={allProjects === true} />
              <Files projectId={selectedProject} tkn={tkn} readOnly={allProjects === true} />
            </StList>

          ) : null

      }

    </StWrapper>

  )

}

export { Projects };
