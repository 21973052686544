import React, { useState } from 'react';
import axios from 'axios';

import { useQuery } from 'react-query';
import { StList, StLabel, StDescription, StActionButton, StInfo } from './styled-ui';
import { StHeader, StSubTitle, StTitleInfoWrapper } from '../styled-ui';

import { ProjectCreator } from './ProjectCreator';

const ProjectInfo = ({ projectId, refetchProjects, readOnly }) => {
    const [editProject, toggleEditProject] = useState(false);
    const { status, data, error, isFetching, refetch } = useQuery({
        queryKey: ['project', projectId],
        queryFn: async () => {
            const response = await axios(
                {
                    method: 'get',
                    url: '/api/flow',
                    params: {
                        endpoint: 'project',
                        query: `/${projectId}`
                    }
                }
            );
            //console.info('-- get SINGLE project response', response)
            return response.data.project;
        },
    });

    const onSuccess = () => {
        if (refetchProjects) {
            refetchProjects();
        }
        refetch();
    }


    return (
        <StList >
            <StHeader>
                <StSubTitle>Project Info</StSubTitle>
                <StTitleInfoWrapper>
                    {
                        !readOnly && <StActionButton $action='update' onClick={() => { toggleEditProject(!editProject) }}>
                            {editProject ? 'cancel' : 'edit'}
                        </StActionButton>
                    }
                </StTitleInfoWrapper>
            </StHeader>
            <StInfo>
                {
                    isFetching ? <div>loading info...</div> :
                        editProject ? <ProjectCreator onSuccess={onSuccess} isEditMode={true} projectData={data} onCancel={() => toggleEditProject(false)} /> :
                            <>
                                <StLabel>Name: {data.name}</StLabel>
                                {
                                    data.vehicle &&
                                    (
                                        <>
                                            <StLabel>Vehicle info</StLabel>
                                            {Object.keys(data.vehicle).map((key) => { return (<StLabel key={`vehicle-${key}`}>{key}: {data.vehicle[key]}</StLabel>) })}
                                        </>
                                    )
                                }
                                <StLabel>Description</StLabel>
                                <StDescription>{data.description}</StDescription>
                            </>
                }
            </StInfo>
        </StList>
    )
}

export { ProjectInfo };