import { useState } from 'react';
import { StApp, StHeader, StSidebar, StTitle, StTitleInfoWrapper } from './styled-ui';
import { StWrapper, StActionButton } from './components/styled-ui';
import { Analytics } from '@vercel/analytics/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Projects } from './components/Projects';
import { Files } from './components/Files';
import { Auth } from './components/Auth';
import { ErrorPage } from './components/ErrorPage';
/*import {
  Routes,
  Route,
} from "react-router-dom";*/

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    index: true,
    element: <Projects />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/uploads",
    element:<StWrapper> <Files allFiles={true} readOnly={true}/></StWrapper>,
  },
]);

//<Route path="/project" element={<Projects tkn={authInfo.accessToken} />} />

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Set refetchOnWindowFocus to false to prevent automatic refetching when the window regains focus
      refetchOnWindowFocus: false, // default: true
      // Set staleTime to Infinity to cache the data indefinitely
      //staleTime: Infinity,
    },
    mutations: {
      // mutation options
      refetchOnWindowFocus: false,
    }
  },
})

const App = () => {
  const sessionAuth = sessionStorage.getItem('sai');
  const { u, t } = typeof sessionAuth === 'string' ? JSON.parse(sessionAuth) : { u: null, t: null };
  const [authInfo, updateAppAuth] = useState({ userName: u, accessToken: t });

  return (
    <>
      <StApp>
        <StHeader>
          <StTitle><a href='/'>DataTransfer v0.1a</a></StTitle>
          {authInfo && authInfo.userName &&
            <StTitleInfoWrapper>
              Hi, {authInfo.userName} <StActionButton $action='update' onClick={() => {
                sessionStorage.removeItem('sai');
                updateAppAuth({ userName: null, accessToken: null })
              }}>logout</StActionButton>
            </StTitleInfoWrapper>
          }
        </StHeader>

        <QueryClientProvider client={queryClient}>

          {authInfo && authInfo.accessToken ?
            <RouterProvider router={router} /> : <Auth updateAppAuth={updateAppAuth} />}
            {/*<StSidebar>
                <RouterProvider router={router} />
          </StSidebar> : <Auth updateAppAuth={updateAppAuth} />}*/}
        </QueryClientProvider>

      </StApp>
      <Analytics />
    </>
  );
}

export default App;
