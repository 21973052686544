import React, { useState } from 'react';
import {
    useMutation,
} from 'react-query';

import { useForm } from "react-hook-form";
import { StForm, StInput, StButton, StNav, StError } from './styled-ui';

import axios from 'axios';

const Auth = ({updateAppAuth}) => {
    
    const { register, handleSubmit, getValues, formState: { errors, isValid }} = useForm();

    const [isAuthentificated, updateAuth] = useState(false);
    const [isRegistration, updateRegistration] = useState(false);
    const [error, setError] = useState(null);

    const authMutation = useMutation({
        mutationFn: (params) => {
            return axios.post('/api/flow', { ...params })
        },
        onSuccess: (data) => {
            const { data: { success } } = data;
            if (success) {
                const { data: { access_token, user: { name: userName, role_id } } } = data;
                updateAppAuth({userName, accessToken: access_token});
                sessionStorage.setItem('sai', JSON.stringify({ u: userName, t: access_token, r: role_id }));
                updateAuth(true);
            } else {
                const { data: { message } } = data;
                setError(message || `We can't process your request. Please try again later.`);
            }
        },
        onError: (error) => {
            console.error('--- error', error)
            updateAuth(false);
        }
    });

    const doLogin = (data) => {
        const { email, password } = data;
        authMutation.mutate({ payload: { email, password }, endpoint: 'login' });
    };

    const doRegister = (data) => {
        const { email, password, name } = data;
        const regex = /[^a-zA-Z]/g;
        const username = email.replace(regex, '').slice(0, 15);
        authMutation.mutate({ payload: { email, username, password, name }, endpoint: 'register' });
    };
    
    const loginDisabled = authMutation.isLoading || !isValid;
    const registerDisabled = isRegistration && !isValid;

    return isAuthentificated ? (
        <>
            <h2>Authentificated</h2>
        </>
    ) : (
        <StForm onSubmit={handleSubmit(isRegistration ? doRegister : doLogin)}>
            {error && <StError>{error}</StError>}
            <StInput
                type="text"
                placeholder="E-Mail"
                id="email"
                key="email"
                $isInvalid={errors.email}
                {...register("email", {required: true, pattern: /^\S+@\S+\.\S+$/i})}
                
            />
            {
                isRegistration && (
                    <StInput
                        type="text"
                        placeholder="Name"
                        id="name"
                        key="name"
                        $isInvalid={errors.name}
                        {...register("name", {required: true, maxLength: 20})}                        
                    />
                )}
            <StInput
                type="password"
                placeholder="Password (8chars min)"
                id="password"
                key="password"
                $isInvalid={errors.password}
                {...register("password", {required: true, minLength: 8})}
            />
            {
                isRegistration && (
                    <StInput
                        type="password"
                        placeholder="Repeat Password"
                        id="passwordCheck"
                        key="passwordCheck"
                        $isInvalid={errors.passwordCheck}
                        {...register("passwordCheck", {required: true, minLength: 8, validate: value => value === getValues('password')})}
                    />
                )}
            <StNav>
                <StButton type="submit" disabled={loginDisabled || registerDisabled}>
                    {authMutation.isLoading ? 'processing...' : isRegistration ? 'register' : 'login'}
                </StButton>
                <StButton type="button" onClick={() => updateRegistration(!isRegistration)} >
                    {isRegistration ? 'cancel' : 'register'}
                </StButton>
            </StNav>
        </StForm>
    )
};

export { Auth };
